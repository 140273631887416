import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Hamburger from './Hamburger';
import '../styles/Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

const Navbar = () => {
  const [showPanels, setShowPanels] = useState(false);

  useEffect(() => {
    document.body.style.overflow = showPanels ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showPanels]);

  const togglePanels = (isOpen) => {
    setShowPanels(isOpen);
  };

  const handleLinkClick = () => {
    setShowPanels(false);
    window.scrollTo(0, 0);
  };

  return (
      <div className="screen">
        <div className="navbar">
          <div className="logo">
            <img
                src="logo192.png"
                alt="Tuutori Keskus Logo"
                className="tuutorikeskus-logo"
            />
          </div>
          {/* Navigation Panel */}
          <div className={`nav-links ${showPanels ? 'show' : ''}`}>
            <Link to="/" onClick={handleLinkClick}>
              Koti
            </Link>
            <Link to="/Kurssit" onClick={handleLinkClick}>
              Kurssit
            </Link>
            <Link to="/Romania" onClick={handleLinkClick}>
              Romania
            </Link>
            <Link to="/Meistä" onClick={handleLinkClick}>
              Meistä
            </Link>
            <Link to="/Yhteistiedot" onClick={handleLinkClick}>
              Yhteistiedot
            </Link>
          </div>
          {/* Contact Panel */}
          <div className={`contact-panel ${showPanels ? 'show' : ''}`}>
            <h3>Sähköposti</h3>
            <p>
              <a href="mailto:info.tuutorikeskus@gmail.com">
                info.tuutorikeskus@gmail.com
              </a>
            </p>
            <h3>Puhelin</h3>
            <p>
              <a href="tel:+358442447576">
                +358 442 447 576
              </a>
            </p>
            <h3>Sosiaalimediat</h3>
            <div className="social-icons">
              <a
                  href="https://www.facebook.com/profile.php?id=100063566469459"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <FontAwesomeIcon color="#4267B2" icon={faFacebook} />
              </a>
              <a
                  href="https://www.instagram.com/tuutorikeskus/"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <FontAwesomeIcon color="#212121" icon={faInstagram} />
              </a>
              <a
                  href="https://www.youtube.com/@TuutoriKeskus"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <FontAwesomeIcon color="#FF0000" icon={faYoutube} />
              </a>
            </div>
          </div>
          <Hamburger onToggle={togglePanels} />
        </div>
      </div>
  );
};

export default Navbar;
